import {
	objectValuesToOptions,
	randomizeArray,
	recordToOptions,
} from "../../../utils"

export const JOB_TITLES = {
	OPERATIONS: "Operations",
	IT_ADMIN: "IT Admin",
	OFFICE_MANAGER: "Office Manager",
	FACILITY_MANAGER: "Facility Manager",
	HUMAN_RESOURCES: "Human resources",
	FOUNDER_OR_CEO: "Founder / CEO",
	FINANCE: "Finance",
	MARKETING: "Marketing",
	PRODUCT_DESIGN: "Product Design",
	INTERNAL_COMMUNICATION: "Internal Communication",
	SALES: "Sales",
	ENGINEERING: "Engineering",
	PRODUCT_MANAGEMENT: "Product Management",
	STUDENT: "Student",
	OTHER: "Other",
}

export const JOB_TITLES_OPTIONS = randomizeArray(
	objectValuesToOptions(JOB_TITLES),
)

export const JOB_ROLES = {
	EXECUTIVE: "Executive (C-level/VP)",
	DEPARTMENT_LEAD: "Department lead",
	TEAM_MANAGER: "Team Manager",
	TEAM_MEMBER: "Team Member",
	FREELANCER: "Freelancer",
	MYSELF: "Using just for myself",
} as const

export const JOB_ROLES_OPTIONS = randomizeArray(
	objectValuesToOptions(JOB_ROLES),
)

export const DISCOVERIES = {
	FRIEND_OR_WORD_OF_MOUTH: "Word of mouth (friend, work)",
	GOOGLE: "Google (Search engine)",
	YOUTUBE: "Youtube",
	LINKEDIN: "LinkedIn",
	TWITTER: "Twitter",
	FACEBOOK_OR_INSTAGRAM: "Facebook / Instagram",
	PODCAST_OR_RADIO: "Podcast / Radio",
	G2: "G2",
	OTHER: "Other",
} as const

export const DISCOVERIES_OPTIONS = randomizeArray(
	objectValuesToOptions(DISCOVERIES),
)

export const NONE_OF_THOSE = "NONE_OF_THOSE"

export const USE_CASES = {
	ROOMS: "Manage Meeting Rooms",
	ASSETS: "Manage Company Assets",
	DESKS: "Manage Desk Reservations",
	VISITORS: "Manage Visitors",
	[NONE_OF_THOSE]: "None of those",
} as const

export type UseCases = keyof typeof USE_CASES

export const isUseCase = (value: string): value is UseCases =>
	Object.keys(USE_CASES).includes(value)

export const USE_CASES_OPTIONS = recordToOptions(USE_CASES)
