import React, {
	CSSProperties,
	ForwardedRef,
	MouseEvent,
	PropsWithChildren,
	RefObject,
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"

import classNames from "classnames"
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom"

import PlanImage, { Dimension } from "./PlanImage"
import { UpdateAction } from "react-quick-pinch-zoom/esm/PinchZoom/types"

import { FloorResponse } from "../redux/floors/types"

import { ReactComponent as MinusSVG } from "../assets/images/icons/Minus.svg"
import { ReactComponent as PlusSVG } from "../assets/images/icons/Plus.svg"
import { ReactComponent as TriDownSVG } from "../assets/images/icons/Tri down.svg"
import { ReactComponent as TriLeftSVG } from "../assets/images/icons/Tri left.svg"
import { ReactComponent as TriRightSVG } from "../assets/images/icons/Tri right.svg"
import { ReactComponent as TriUpSVG } from "../assets/images/icons/Tri up.svg"

import "./Map.sass"

const IMAGE_SCALE_FACTOR = 3

type Transform = {
	value: string
	x: number
	y: number
	scale: number
}
type Vector = {
	x: number
	y: number
}

type ZoomButtonsProps = {
	onZoomInClick: () => void
	onZoomOutClick: () => void
}

function ZoomButtons({ onZoomInClick, onZoomOutClick }: ZoomButtonsProps) {
	return (
		<div className="ZoomButtons map-buttons">
			<span onClick={onZoomOutClick}>
				<MinusSVG />
			</span>
			<span onClick={onZoomInClick}>
				<PlusSVG />
			</span>
		</div>
	)
}

type PanButtonsProps = {
	onUpClick: (e: MouseEvent<HTMLSpanElement>) => void
	onRightClick: (e: MouseEvent<HTMLSpanElement>) => void
	onDownClick: (e: MouseEvent<HTMLSpanElement>) => void
	onLeftClick: (e: MouseEvent<HTMLSpanElement>) => void
}

function PanButtons({
	onUpClick,
	onRightClick,
	onDownClick,
	onLeftClick,
}: PanButtonsProps) {
	return (
		<div className="PanButtons map-buttons">
			<span className="up" onClick={onUpClick}>
				<TriUpSVG />
			</span>
			<span className="right" onClick={onRightClick}>
				<TriRightSVG />
			</span>
			<span className="down" onClick={onDownClick}>
				<TriDownSVG />
			</span>
			<span className="left" onClick={onLeftClick}>
				<TriLeftSVG />
			</span>
		</div>
	)
}

function getCenterCoord(floorPlan: HTMLDivElement, transform: Transform) {
	const width = floorPlan.clientWidth
	const height = floorPlan.clientHeight

	const scaledWidth = width * transform.scale
	const scaledHeight = height * transform.scale

	const aimX = width / 2 - transform.x * transform.scale
	const aimY = height / 2 - transform.y * transform.scale

	const aimXRatio = aimX / scaledWidth
	const aimYRatio = aimY / scaledHeight

	const x = aimXRatio * width
	const y = aimYRatio * height

	return { x, y }
}

type MapPlainProps = {
	map: FloorResponse
	onClick?: (e: MouseEvent<HTMLImageElement>) => void
	onUpdateScale?: (value: number) => void
	onIsReady?: () => void
	isDisabled?: boolean
	showCrosshair?: boolean
	showZoomControls?: boolean
	showPanControls?: boolean
	className?: string
	mapBoxRef?: RefObject<HTMLDivElement>
}

export const MapPlain = (
	{
		children,
		map,
		onUpdateScale,
		onClick,
		isDisabled,
		showCrosshair,
		showZoomControls,
		showPanControls,
		className,
		mapBoxRef,
		onIsReady,
	}: PropsWithChildren<MapPlainProps>,
	ref: ForwardedRef<HTMLImageElement>,
) => {
	const [transform, setTransform] = useState<Transform>({
		value: "",
		x: 0,
		y: 0,
		scale: 1,
	})
	const [canRenderMap, setCanRenderMap] = useState<boolean>(false)

	const qpzRef = useRef<QuickPinchZoom>(null)
	const floorPlanRef = useRef<HTMLDivElement>(null)
	const containerRef = useRef<HTMLDivElement>(null)

	const onUpdate = useCallback(
		(values: UpdateAction) => {
			const { x, y, scale } = values
			const { current: floorPlan } = floorPlanRef

			if (!floorPlan) return false

			const value = make3dTransformValue({ x, y, scale })
			setTransform({
				x,
				y,
				scale,
				value,
			})

			onUpdateScale?.(scale)
		},
		[onUpdateScale],
	)

	const handleZoom = useCallback(
		(multiplier: number) => {
			const { current: qpz } = qpzRef
			const { current: floorPlan } = floorPlanRef

			if (!qpz || !floorPlan) return false

			qpz.scaleTo({
				...getCenterCoord(floorPlan, transform),
				scale: transform.scale * multiplier,
				duration: 128,
			})
		},
		[transform],
	)

	const handlePan = useCallback(
		(e: MouseEvent<HTMLSpanElement>, vector: Vector) => {
			e && e.preventDefault()

			const { current: qpz } = qpzRef
			const { current: floorPlan } = floorPlanRef

			if (!qpz || !floorPlan) return false

			const width = floorPlan.clientWidth
			const height = floorPlan.clientHeight
			const moveBy = width > height ? width / 4 : height / 4

			const { x, y } = getCenterCoord(floorPlan, transform)

			const newX = x + (vector.x * moveBy) / transform.scale
			const newY = y + (vector.y * moveBy) / transform.scale

			qpz.alignCenter({
				x: newX,
				y: newY,
				scale: transform.scale,
				duration: 128,
			})
		},
		[transform],
	)

	const [dimensions, setDimensions] = useState<Dimension>({
		width: 1,
		height: 1,
	})

	useEffect(() => {
		if (!containerRef.current) return

		const { clientWidth: width, clientHeight: height } = containerRef.current

		setDimensions({ width, height })
	}, [])

	const imageAspect = (map.width || 1) / (map.height || 1)

	const containerAspect = useMemo(
		() => dimensions.width / dimensions.height,
		[dimensions],
	)

	const mapClassName = classNames({
		Map: true,
		showCrosshair,
		[className ?? ""]: Boolean(className),
	})

	// display map only when layout is ready
	useEffect(() => {
		requestAnimationFrame(() => {
			setCanRenderMap(true)
		})
	}, [onIsReady])

	const imageResizeOptions = useMemo(() => {
		if (map?.image?.replaceAll("/", "").endsWith(".svg")) {
			return
		}

		//use longer axis to calculate resize size
		const resizeSize = Math.floor(
			(containerAspect < 1 ? dimensions.height : dimensions.width) *
				IMAGE_SCALE_FACTOR,
		)

		const imageWidth = map?.width ?? 0
		const imageHeight = map?.height ?? 0
		const isHorizontal = imageWidth >= imageHeight

		if (isHorizontal ? imageWidth < resizeSize : imageHeight < resizeSize) {
			return
		}

		return {
			...(isHorizontal ? { width: resizeSize } : { height: resizeSize }),
			mode: 1,
		}
	}, [
		containerAspect,
		dimensions.height,
		dimensions.width,
		map?.height,
		map?.image,
		map?.width,
	])

	return (
		<div className="MapContainer" ref={containerRef}>
			{canRenderMap && (
				<div ref={mapBoxRef}>
					{showZoomControls && (
						<ZoomButtons
							onZoomInClick={() => handleZoom(3 / 2)}
							onZoomOutClick={() => handleZoom(2 / 3)}
						/>
					)}

					{showPanControls && (
						<PanButtons
							onUpClick={(e: MouseEvent<HTMLSpanElement>) =>
								handlePan(e, { x: 0, y: -1 })
							}
							onRightClick={(e: MouseEvent<HTMLSpanElement>) =>
								handlePan(e, { x: 1, y: 0 })
							}
							onDownClick={(e: MouseEvent<HTMLSpanElement>) =>
								handlePan(e, { x: 0, y: 1 })
							}
							onLeftClick={(e: MouseEvent<HTMLSpanElement>) =>
								handlePan(e, { x: -1, y: 0 })
							}
						/>
					)}

					<QuickPinchZoom
						ref={qpzRef}
						enabled={!isDisabled}
						onUpdate={onUpdate}
						shouldInterceptWheel={() => false}
						wheelScaleFactor={400}
						animationDuration={64}
						inertiaFriction={0.84}
						draggableUnZoomed={false}
					>
						<div
							className={mapClassName}
							ref={floorPlanRef}
							style={
								{
									width: `${dimensions.width}px`,
									height: `${dimensions.height}px`,
									transform: transform.value,
									"--seat-scale": 1 / transform.scale,
								} as CSSProperties
							}
						>
							<PlanImage
								isWider={imageAspect > containerAspect}
								isTaller={imageAspect < containerAspect}
								isSame={imageAspect === containerAspect}
								containerSize={dimensions}
								src={map.image!}
								imageResizeOptions={imageResizeOptions}
								ref={ref}
								onClick={(e: MouseEvent<HTMLImageElement>) => {
									if (!isDisabled) {
										typeof onClick === "function" && onClick(e)
									}
								}}
								onIsLoaded={() => onIsReady?.()}
							>
								{children}
							</PlanImage>
						</div>
					</QuickPinchZoom>
				</div>
			)}
		</div>
	)
}

const Map = forwardRef<HTMLImageElement, PropsWithChildren<MapPlainProps>>(
	MapPlain,
)

export default Map
